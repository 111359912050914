import React, { Component } from "react";
import { Alert } from "reactstrap";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { firebase_app } from "./../../../../data/config";

class NoticeMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logged: this.props.data.logged,
      verifiedUser: this.props.data.verifiedUser,
      credit: this.props.data.credit,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data.logged !== this.props.data.logged || prevProps.data.verifiedUser !== this.props.data.verifiedUser || prevProps.data.credit !== this.props.data.credit) {
      this.setState({ logged: this.props.data.logged, verifiedUser: this.props.data.verifiedUser, credit: this.props.data.credit });
    }
  }

  sendVerificationEmail = async () => {
    await firebase_app.auth().currentUser.reload();
    const user = firebase_app.auth().currentUser;

    if (user) {
      if (user.emailVerified) {
        this.setState({ verifiedUser: user.emailVerified });
      } else {
        user
          .sendEmailVerification({
            url: "https://app.writebot.io/",
          })
          .then(() => {
            toast.info("Verification e-mail sent", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          })
          .catch((err) => {
            console.log(err);
            toast.error("Email already sent - check your spam or try later", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    }
  };

  render() {
    return (
      <div>
        {/* Notice Message  */}

        {/* Logged and verifiedUser */}
        {this.state.logged && this.state.verifiedUser ? (
          <Alert className="card" color="outline">
            <div className="row justify-content-between">
              <div className={isMobile ? "col-12 card-body text-center " : "col-10 my-auto"}>
                <p>
                  <strong>{`You have ${Math.round(100 * this.state.credit) / 100} credit(s)`} </strong>
                </p>
              </div>
              <div className={isMobile ? "card-body text-center   " : "col-2"}>
                <a className="btn-action" style={{ color: "#2f4050" }} href={`${process.env.PUBLIC_URL}/profile`}>
                  Buy More
                </a>
              </div>
            </div>
          </Alert>
        ) : (
          <div></div>
        )}
        {/* Logged but not verified */}
        {this.state.logged && !this.state.verifiedUser ? (
          <Alert className="card" color="outline">
            <div className="row justify-content-between">
              <div className={isMobile ? "col-12  card-body text-center " : "col-7 my-auto"}>
                <p>
                  <strong>Please verified your email - check you spam ! </strong>
                </p>
              </div>
              <div className={isMobile ? "card-body text-center " : "col-3 my-auto"}>
                <button className="btn-action" onClick={this.sendVerificationEmail}>
                  Send e-mail
                </button>
              </div>
            </div>
          </Alert>
        ) : (
          <div></div>
        )}
        {/* Not logged */}

        {this.state.logged ? (
          <div></div>
        ) : (
          <Alert className="card" color="outline">
            <div className="row justify-content-between">
              <div className={isMobile ? "col-12 card-body text-center " : "col-9 my-auto"}>
                <p>
                  <strong>You need to create an account ! Get 7 credits for free !</strong>
                </p>
              </div>
              <div className={isMobile ? "card-body text-center" : "col-3 my-auto"}>
                <a className="btn-action" style={{ color: "#2f4050" }} href={`${process.env.PUBLIC_URL}/signup`}>
                  Sign Up
                </a>
              </div>
            </div>
          </Alert>
        )}

        {/* End notice message  */}
      </div>
    );
  }
}

export default NoticeMessage;
