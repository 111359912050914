import React, { Fragment, Component } from "react";
import { isMobile } from "react-device-detect";
import { firebase_app } from "../../../../data/config";
import { getCredit } from "../../../../firebase-postGenerator/firebase-frontend";

class Credit extends Component {
  state = {
    credit: 0,
    totCredit: "N/A",
  };
  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        getCredit().then((r) => {
          this.setState({ credit: r.credit, totCredit: r.totCredit });
        });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.componentDidMount();
    }
  }

  render() {
    return (
      <Fragment>
        <div className="card m-t-10">
          <div className="container">
            <div className="col-sm-12 col-xl-12">
              <div className="card-body ">
                <h4>
                  <strong>Credit</strong>
                </h4>

                <h6>Pay as you go </h6>
                <p>Control your cost by purchasing your credits in advance.</p>

                <div className="p-b-20 p-t-20 m-t-20" style={{ borderTopStyle: "solid", borderTopColor: "#ececf1", borderTopWidth: "1.5px", borderBottomStyle: "solid", borderBottomColor: "#ececf1", borderBottomWidth: "1.5px" }}>
                  <div className="row">
                    <div className={isMobile ? "col-6 my-auto  text-center" : "col-3 my-auto  text-center"}>
                      <div className="m-b-10" style={{ fontWeight: "bolder" }}>
                        Available Credits
                      </div>
                      <div>{Math.round(100 * this.state.credit) / 100}</div>
                    </div>
                    <div className={isMobile ? "col-6 my-auto  text-center" : "col-3 my-auto  text-center"}>
                      <div className="m-b-10" style={{ fontWeight: "bolder" }}>
                        Total Credits bought
                      </div>
                      <div>{this.state.totCredit}</div>
                    </div>
                    <div className={isMobile ? "col-0" : "col-3"}></div>
                    {isMobile ? (
                      <div></div>
                    ) : (
                      <div className="col-2 my-auto">
                        <button className="btn btn-action-light">Buy Credits</button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Credit;
