import React, { Component, Fragment } from "react";
import { firebase_app } from "../../../data/config";
import { getCredit } from "../../../firebase-postGenerator/firebase-frontend";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import NoticeMessage from "./component/NoticeMessage";
import Form from "react-bootstrap/Form";
import { setupForm } from "./assets/setupForm";
import { languageAvailable } from "./assets/language";

class FormStandard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idProduct: this.props.idProduct,
      idea: "",
      setup: {
        name: "Blogpost",
        heading: "Write, submit and get your content!",
        explanation: (
          <p>
            For every submission, you will need to use two credits to generate an original post of approximately 500-1000 words. Let your imagination run wild and be astounded! Need more proof ? Check our{" "}
            <a href={"https://writebot.io/bibliotheque.html"} target="_blank" rel="noopener noreferrer">
              library of examples
            </a>
          </p>
        ),
        placeholder: "My blog post idea (ex: Food for Cat, Best practice for SEO )",
        placeholderMobile: "ex: Food for Cat, Best practice for SEO",
        buttonAction: "Create my post !",
        cost: "Cost 2 credits",
        firebaseFunction: "getPost",
      },
      myPost: "<h1>Hello World!</h1>",
      isLoading: false,
      showModal: false,
      verifiedUser: false,
      credit: 0,
      language: "English",
    };
  }

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        // this.setState({ logged: true });
        // Read Credit
        getCredit()
          .then((r) => {
            const setup = this.getSetupProduct(this.state.idProduct);
            this.setState({ logged: true, verifiedUser: r.verifiedUser, credit: r.credit, setup });
          })
          .catch();
      } else {
        const setup = this.getSetupProduct(this.state.idProduct);
        this.setState({ logged: false, setup });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.idProduct !== this.props.idProduct) {
      const setup = this.getSetupProduct(this.props.idProduct);
      this.setState({ setup, idProduct: this.props.idProduct });
    }
  }

  getSetupProduct = (idProduct) => {
    let setup = {};
    setupForm.forEach((e) => {
      if (e.id === idProduct) {
        setup = e;
      }
    });

    return setup;
  };

  handleAnswer = (e) => {
    this.props.onAnswer(e);
  };

  getPostFirebase = async () => {
    // Check if user connected
    if (this.state.idea.length === 0) {
      setTimeout(() => {
        toast.error("Feed the AI with an idea, the field is empty !");
      }, 200);
      return false;
    }

    const user = firebase_app.auth().currentUser;
    if (user) {
      if (!user.emailVerified) {
        await firebase_app.auth().currentUser.getIdToken(true);
      }

      this.handleAnswer({ isLoading: true });

      console.log();
      const getAI = firebase_app.functions().httpsCallable(this.state.setup.firebaseFunction);
      getAI({ idea: this.state.idea, type: "", uid: user.uid, language: this.state.language })
        .then((res) => {
          // GEstion des erreurs :
          const code = res.data.code;

          if (code === 114) {
            setTimeout(() => {
              toast.error("Not enough credits");
            }, 200);
          }

          if (code === 403) {
            setTimeout(() => {
              toast.error(res.data.res);
            }, 200);
          }

          let image = [];
          if ("image" in res.data) {
            image = res.data.image;
          }

          this.handleAnswer({ myPost: res.data.res, idDocument: res.data.id, isLoading: false, image });
          this.setState({ idea: "" });
          // Recalculate credit
          this.componentDidMount();
        })
        .catch((e) => {
          this.handleAnswer({ idDocument: null, myPost: "Error", isLoading: false });
          setTimeout(() => {
            toast.error("An error occured, please try later...sorry");
          }, 200);
        });
    } else {
      this.setState({ showModal: true });
      console.log("Not logged");
      setTimeout(() => {
        toast.error("You must be connected, please log in !");
      }, 200);
    }

    // firebase_app.auth().onAuthStateChanged((user) => {
    //   if (user) {

    //   }
    // });
  };

  handleChange = (event) => {
    event.preventDefault();
    this.setState({ idea: event.target.value });
  };

  render() {
    return (
      <Fragment>
        {/* Notice  */}

        <div>
          <NoticeMessage data={{ logged: this.state.logged, verifiedUser: this.state.verifiedUser, credit: this.state.credit }} />
        </div>
        <div>
          <div className="card m-t-10">
            <div className="container">
              <div className="col-sm-12 col-xl-12">
                <div className="card-body ">
                  <h4 className="m-b-20">
                    <strong>{this.state.setup.heading}</strong>
                    <div className="verifiedUser m-l-10">{this.state.setup.cost}</div>
                  </h4>
                  {this.state.setup.explanation}

                  {/* Input Idea  */}

                  <div className="input-group mb-3">
                    <input
                      type="text"
                      value={this.state.idea}
                      onChange={this.handleChange}
                      placeholder={isMobile ? this.state.setup.placeholderMobile : this.state.setup.placeholder}
                      className="form-control"
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-default"
                    />
                    {/* Input Language */}
                    {!isMobile && (
                      <Form.Select
                        onChange={(e) => {
                          this.setState({ language: e.target.value });
                        }}
                      >
                        {languageAvailable.map((e) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </Form.Select>
                    )}

                    {/* End input Language */}
                  </div>

                  {isMobile && (
                    <Form.Select
                      className="m-b-20"
                      onChange={(e) => {
                        this.setState({ language: e.target.value });
                      }}
                    >
                      {languageAvailable.map((e) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </Form.Select>
                  )}

                  {/* Button */}
                  <div className="row mx-auto align-items-center justify-content-md-center m-b-20">
                    <div className={isMobile ? "col" : ""}>
                      <div className="row">
                        <button
                          disabled={this.state.isLoading || this.state.idea.length === 0}
                          className={this.state.isLoading || this.state.idea.length === 0 ? `btn-action disabled ${isMobile ? "col-12" : ""}` : `btn-action ${isMobile ? "col-12" : ""}`}
                          onClick={() => {
                            this.getPostFirebase();
                          }}
                        >
                          {this.state.setup.buttonAction}
                        </button>
                        <div className="col-1"></div>
                        <button
                          className={this.state.isLoading ? `btn-action-light disabled ${isMobile ? "col-12" : ""}` : `btn-action-light ${isMobile ? "col-12" : ""}`}
                          onClick={() => {
                            this.handleAnswer({ myPost: this.state.setup.example, image: this.state.setup.image });
                          }}
                        >
                          {" "}
                          See Example
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Answer */}

          {/* <Viewer data={{ myPost: this.state.myPost, isLoading: this.state.isLoading, displayTitle: true }} /> */}
          {/* <Viewer2 data={{ myPost: this.state.myPost, isLoading: this.state.isLoading, displayTitle: true }} /> */}
        </div>
      </Fragment>
    );
  }
}

export default FormStandard;
