import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { firebase_app } from "../../../data/config";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    // stripePromise = loadStripe("pk_test_51OCSmzGvLt5qyCbnaqiVUROEZxdfK0Mhd9xkHYEYDFH5CHGWqprrMdKj9tQ0Xm4hU6i2B6FCPP3SvHLIRlL7xo4Q00lkYr1pYD");
    stripePromise = loadStripe("pk_live_51OCSmzGvLt5qyCbnC4Q9EH44P1MntMhBiZ7qbVfgWmST00bS4HKFCKm33DpuVmVyuL65QDTB0E7ApK7EeOuuQ6yj00UkwFsqv5");
  }
  return stripePromise;
};

const Stripe = ({ data }) => {
  const [userInfo, setUserInfo] = useState();
  const [priceId, setPriceId] = useState();

  useEffect(() => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        const email = user.email;
        const uid = user.uid;
        setUserInfo({ email, uid });
      }
    });
  }, []);

  useEffect(() => {
    console.log(data.amount);

    // Test
    // if (data.amount === 2.2) setPriceId("price_1OCkziGvLt5qyCbngpXtV3Au");
    // if (data.amount === 4.9) setPriceId("price_1OCkziGvLt5qyCbngpXtV3Au");
    // if (data.amount === 9.9) setPriceId("price_1OCkziGvLt5qyCbngpXtV3Au");

    // prod
    if (data.amount === 2.2) setPriceId("price_1OCktTGvLt5qyCbnzf5IsPGC");
    if (data.amount === 4.9) setPriceId("price_1OCkcnGvLt5qyCbnvUjH4VjP");
    if (data.amount === 9.9) setPriceId("price_1OCqQ9GvLt5qyCbnBmaMsTOJ");
  }, [data]);
  // 7156

  async function handleCheckout() {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: priceId,
          quantity: 1,
        },
      ],
      mode: "payment",
      successUrl: `https://app.writebot.io/`,
      cancelUrl: `https://app.writebot.io/`,
      customerEmail: userInfo.email,
      clientReferenceId: userInfo.uid,
    });
    // console.warn(error.message);
  }

  return (
    <div className="text-center">
      <button className="btn-action" onClick={handleCheckout}>
        Buy Credits!
      </button>
    </div>
  );
};

export default Stripe;
