// https://lexical.dev/docs/concepts/serialization
// https://codesandbox.io/s/lexical-markdown-plugin-example-4076jq?file=/src/SampleText.js:155-172
import React from "react";
import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { $generateNodesFromDOM } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot } from "lexical";
import { getProperHtml } from "./utils/modificationSelection";
import "./themes/styles.css";
// import FloatingTextFormatToolbarPlugin from "./plugins/FloatingPlugin.tsx";
import { $createCodeNode } from "@lexical/code";
import prepopulatedText from "./utils/SampleText";

function Placeholder() {
  return <div className="editor-placeholder">Hello world !</div>;
}

const getInitialState = (editor, initialValue) => {
  editor.update(() => {
    // In the browser you can use the native DOMParser API to parse the HTML string.
    const parser = new DOMParser();
    const dom = parser.parseFromString(initialValue, "text/html");
    // Once you have the DOM instance it's easy to generate LexicalNodes.
    const nodes = $generateNodesFromDOM(editor, dom);
    const root = $getRoot();
    nodes.map((node) => root.append(node));
  });
};

const getEditorConfig = (initialValue) => {
  return {
    theme: ExampleTheme,
    // editable: false,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [HeadingNode, ListNode, ListItemNode, QuoteNode, CodeNode, CodeHighlightNode, TableNode, TableCellNode, TableRowNode, AutoLinkNode, LinkNode],
    // editorState: (editor) => getInitialState(editor, initialValue),
    // Any custom nodes go here
    editorState: prepopulatedText(),
  };
};

const editorConfig = {
  editorState: prepopulatedText,
  theme: ExampleTheme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [HeadingNode, ListNode, ListItemNode, QuoteNode, CodeNode, CodeHighlightNode, TableNode, TableCellNode, TableRowNode, AutoLinkNode, LinkNode],
};

export default function Editor({ data }) {
  const html = data.html;
  // const idDocument = data.idDocument;

  function TextLoader({ note }) {
    const [editor] = useLexicalComposerContext();
    if (note === "") {
      return null;
    }

    editor.update(() => {
      $getRoot().clear();
      const root = $getRoot();
      if (note.includes("<code>")) {
        const code = $createCodeNode(note);

        root.append(code);
      } else {
        const dom = getProperHtml(note);
        const nodes = $generateNodesFromDOM(editor, dom);
        nodes.map((node) => root.append(node));
      }
    });

    return null;
  }

  return (
    <div>
      <LexicalComposer initialConfig={editorConfig}>
        <div className="editor-container">
          <ToolbarPlugin data={{ idDocument: data.idDocument }} />
          <div className="editor-inner">
            <RichTextPlugin contentEditable={<ContentEditable a={html} className="editor-input" />} placeholder={<Placeholder />} />
            <ListPlugin />
            <HistoryPlugin />
            <LinkPlugin />

            <TextLoader note={html} />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          </div>
        </div>
      </LexicalComposer>
    </div>
  );
}

// export default Editor;
