import React, { Fragment, Component } from "react";
import { isMobile } from "react-device-detect";
import { firebase_app } from "../../../../data/config";
import StandardAvatar from "./standardAvatar.png";
import { toast } from "react-toastify";

class Profile extends Component {
  state = {};
  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        let displayName = "Anonymous";
        if (user.displayName !== null) {
          displayName = user.displayName;
        }

        let photoURL = StandardAvatar;
        // let photoURL = "https://lh3.googleusercontent.com/a/AEdFTp7z77HnQ5gq63o8hn6DutGH46kUDesdXfmQEJFC=s96-c";

        if (user.photoURL !== null) {
          photoURL = user.photoURL;
        }
        this.setState({ email: user.email, displayName, verified: user.emailVerified, photoURL });
      }
    });
  };

  sendVerificationEmail = async () => {
    await firebase_app.auth().currentUser.reload();
    const user = firebase_app.auth().currentUser;

    if (user) {
      if (user.emailVerified) {
        this.setState({ verifiedUser: user.emailVerified });
      } else {
        user
          .sendEmailVerification({
            url: "https://app.writebot.io/",
          })
          .then(() => {
            toast.info("Verification e-mail sent", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          })
          .catch((err) => {
            console.log(err);
            toast.error("Email already sent - check your spam or try later", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    }
  };

  Logout_From_Firebase = () => {
    firebase_app.auth().signOut();
  };

  render() {
    return (
      <Fragment>
        <div className="card m-t-10">
          <div className="container">
            <div className="col-sm-12 col-xl-12">
              <div className="card-body ">
                <h4>
                  <strong>Profile</strong>
                </h4>
                <div className="p-b-20 p-t-20 m-t-20" style={{ borderTopStyle: "solid", borderTopColor: "#ececf1", borderTopWidth: "1.5px", borderBottomStyle: "solid", borderBottomColor: "#ececf1", borderBottomWidth: "1.5px" }}>
                  <div className="row">
                    <div className={isMobile ? "col-3 my-auto" : "col-1 my-auto"} style={{ width: "50px" }}>
                      <img src={this.state.photoURL} alt="Profil" style={{ borderRadius: "100px", maxWidth: "100%", maxHeight: "100%" }} />
                    </div>
                    <div className={isMobile ? "col-9" : "col-3"}>
                      <div style={{ fontWeight: "bolder" }}>{this.state.displayName}</div>
                      <div>{this.state.email}</div>
                      <div className={this.state.verified ? "verifiedUser" : "notVerifiedUser"}>{this.state.verified ? "Verified" : "Not verified"}</div>
                    </div>
                    <div className={isMobile ? "" : "col-5"}></div>
                    <div className={isMobile ? "m-t-20 col-12 text-center my-auto" : "col-2 my-auto"}>
                      {this.state.verified ? (
                        <button className="btn btn-action-light" onClick={this.Logout_From_Firebase}>
                          Log Out
                        </button>
                      ) : (
                        <button className="btn btn-action-light" onClick={this.sendVerificationEmail}>
                          Send Verification
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Profile;
