import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { firebase_app } from "./data/config";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { clarity } from "react-microsoft-clarity";
// ** Import custom components for redux **

import App from "./components/app";

// pages
import Signin from "./auth/signin";
import Signup from "./pages/signup";
import ResetPwd from "./pages/resetPwd";

// Markert Advisor
import HomePage from "./components/writly/HomePage";
import ProfileComponent from "./components/writly/profile/ProfileComponent";

const Root = () => {
  // const abortController = new AbortController();
  const [currentUser, setCurrentUser] = useState(false);
  // const [authenticated, setAuthenticated] = useState(false);
  // const jwt_token = localStorage.getItem("token");

  useEffect(() => {
    firebase_app.auth().onAuthStateChanged(setCurrentUser);
  }, []);

  useEffect(() => {
    firebase_app.auth().onAuthStateChanged(setCurrentUser);
    clarity.init("jqic2cj24i");
    clarity.consent();
  }, []);

  return (
    <div className="App">
      <BrowserRouter basename={`/`}>
        <Switch>
          {/* <Route path={`${process.env.PUBLIC_URL}/affiliate/:affiliateID`} component={Signup} /> */}
          <Route path={`${process.env.PUBLIC_URL}/login`} component={Signin} />
          <Route path={`${process.env.PUBLIC_URL}/signup`} component={Signup} />
          <Route path={`${process.env.PUBLIC_URL}/resetPwd`} component={ResetPwd} />
          {/* <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance} />
              <Route path={`${process.env.PUBLIC_URL}/pages/errors/error400`} component={Error400} />
              <Route path={`${process.env.PUBLIC_URL}/pages/errors/error401`} component={Error401} />
              <Route path={`${process.env.PUBLIC_URL}/pages/errors/error403`} component={Error403} />
              <Route path={`${process.env.PUBLIC_URL}/pages/errors/error404`} component={Error404} />
              <Route path={`${process.env.PUBLIC_URL}/pages/errors/error500`} component={Error500} />
              <Route path={`${process.env.PUBLIC_URL}/pages/errors/error503`} component={Error503} />
              <Route path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback />} /> */}

          {currentUser !== null ? (
            <App>
              {/* dashboard menu */}
              {/* <Route
                    exact
                    render={() => {
                      return <Redirect to={`${process.env.PUBLIC_URL}/`} />;
                    }}
                  /> */}

              <Route exact path={`${process.env.PUBLIC_URL}/profile`} component={ProfileComponent} />
              <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomePage} />
            </App>
          ) : (
            // <Redirect to={`${process.env.PUBLIC_URL}/app`} />
            <App>
              {/* dashboard menu */}
              {/* <Route
                    exact
                    render={() => {
                      return <Redirect to={`${process.env.PUBLIC_URL}/`} />;
                    }}
                  /> */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/profile`}
                render={() => {
                  return <Redirect to={`${process.env.PUBLIC_URL}/`} />;
                }}
              />
              <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomePage} />
            </App>
          )}
        </Switch>
      </BrowserRouter>
    </div>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
