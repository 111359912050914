import React, { Component } from "react";
import SelectionProduct from "./formulaire/component/selectionProduct";
import CatchPhrase from "./presentation/CatchPhrase";
import Viewer2 from "./formulaire/component/viewer2";
import FormStandard from "./formulaire/FormStandard";
import CarouselPexel from "./carouselPexel/CarouselPexel";
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      product: 1,
      // myPost: "<code>Test</code>",
      myPost: "",
      image: [],
      isLoading: false,
      idDocument: null,
    };
  }

  executeScroll = () => this.myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  onClick = (e) => {
    this.setState({ product: e });
    this.executeScroll();
  };

  handleAnswer = (e) => {
    this.setState(e);
  };

  render() {
    return (
      <div className="container">
        <CatchPhrase />

        <SelectionProduct handleClick={this.onClick} />
        <div style={{ position: "relative" }}>
          <div ref={this.myRef} style={{ position: "absolute", top: "-100px" }}></div>
        </div>
        <FormStandard onAnswer={this.handleAnswer} idProduct={this.state.product} />
        {this.state.image.length > 0 && <CarouselPexel data={{ image: this.state.image }} />}
        <Viewer2 data={{ idDocument: this.state.idDocument, myPost: this.state.myPost, isLoading: this.state.isLoading, displayTitle: true }} />
      </div>
    );
  }
}

export default HomePage;
