import React, { Component, Fragment } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Paypal from "./paypal";
import { isMobile } from "react-device-detect";
import Stripe from "./stripe";

class PaymentComponent extends Component {
  state = { creditAvailable: [50, 100, 250], priceAvailable: [2.2, 4.9, 9.9], bonusAvailable: [0, 20, 40], credit: 100, bonus: 20, price: 4.9 };
  handlePayment = (e) => {
    this.props.onPayment(e);
  };

  render() {
    const priceInt = parseInt(this.state.price);
    const priceDec = parseInt(100 * (this.state.price - priceInt + 0.001));

    return (
      <Fragment>
        <div className="card m-t-10">
          <div className="card-body ">
            <div className="col-sm-12 col-xl-12">
              <h4>
                <strong>Purchase Credits</strong>
              </h4>
              <div className="row justify-content-md-center">
                <div className={isMobile ? "col-12" : "col-6"}>
                  <div className=" row justify-content-center m-b-20">
                    <div style={{ fontSize: "20px" }}>{"Select your quantity:"}</div>
                  </div>

                  <div className={isMobile ? "row justify-content-center m-b-50 m-5" : "row justify-content-center m-b-50"}>
                    <Slider
                      min={0}
                      max={2}
                      defaultValue={1}
                      marks={this.state.creditAvailable}
                      step={null}
                      onChange={(e) => {
                        this.setState({
                          credit: this.state.creditAvailable[e],
                          bonus: this.state.bonusAvailable[e],
                          price: this.state.priceAvailable[e],
                        });
                      }}
                    />
                  </div>

                  <div className=" row justify-content-center">
                    <div style={{ fontSize: "20px" }}>{`${this.state.credit} credits +${this.state.bonus} free`}</div>
                  </div>

                  <div className=" row justify-content-center m-t-20 m-b-20">
                    <h4 style={{ fontWeight: "bold" }}>
                      <sup>$</sup>
                      {priceInt}
                      <sup>{priceDec === 0 ? "" : "." + priceDec}</sup>
                    </h4>
                  </div>
                  <div className=" row justify-content-center m-b-20">
                    <div style={{ fontSize: "14px" }}>{"No fees, no subscriptions !"}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row justify-content-center m-b-20">
              <div className="col-12 text-center">
                <Paypal data={{ amount: String(this.state.price), credit: this.state.credit + this.state.bonus }} onPayment={this.handlePayment} />
              </div>
            </div> */}

            <Stripe data={{ amount: this.state.price, credit: this.state.credit + this.state.bonus }} />
            <div className="text-center">
              Powered by <strong>Stripe</strong>
            </div>
            <div className="m-t-20"></div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PaymentComponent;
