import React, { Component } from "react";
import PaymentComponent from "../payment/PaymentChoice";
import Credit from "./component/credit";
import Profile from "./component/profil";
import Usage from "./component/usage";
class ProfileComponent extends Component {
  state = { update: true };

  handlePayment = (e) => {
    this.setState({ update: !this.state.update });
  };
  render() {
    return (
      <div className="container">
        <Profile />
        <Credit data={this.state.update} />

        <PaymentComponent onPayment={this.handlePayment} />

        <Usage />
      </div>
    );
  }
}

export default ProfileComponent;
