import React, { Fragment, useEffect, useState, useRef } from "react";
import { firebase_app } from "../../../data/config";
import { isMobile } from "react-device-detect";
import WriteBotLogo from "../../../assets/writebot/WriteBotLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHome, faUser } from "@fortawesome/free-solid-svg-icons";
const Header = () => {
  const Logout_From_Firebase = () => {
    firebase_app.auth().signOut();
    setSubMenu(false);
  };

  const [currentUser, setCurrentUser] = useState(false);
  const [subMenu, setSubMenu] = useState(false);

  useEffect(() => {
    firebase_app.auth().onAuthStateChanged(setCurrentUser);
  }, []);

  if (isMobile) {
    return (
      <Fragment>
        <div className="page-main-header" style={{ zIndex: "120" }}>
          <div className="main-header-right row justify-content-between text-center">
            <div className="col-2">
              <div
                onClick={() => {
                  setSubMenu(!subMenu);
                }}
              >
                <FontAwesomeIcon icon={faBars} size="2x" />
              </div>
            </div>
            <div className="col-8">
              <a href={`${process.env.PUBLIC_URL}/`} style={{ cursor: "pointer", color: "#101010" }}>
                <img src={WriteBotLogo} alt="logo" style={{ height: "30px" }} />
              </a>
            </div>
            <div className="col-2"></div>

            {/* {currentUser !== null ? (
              <div className="col-6 ">
                <div className="row d-flex justify-content-center text-center">
                  <div className="col-12 d-flex justify-content-center text-center">
                    <a href={`${process.env.PUBLIC_URL}/`} style={{ cursor: "pointer", fontSize: "18px", color: "#6e6e80" }}>
                      Home
                    </a>

                    <a className="m-l-50" href={`${process.env.PUBLIC_URL}/profile`} style={{ cursor: "pointer", fontSize: "18px", color: "#6e6e80" }}>
                      Profile
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-7 ">
                <div className="row d-flex justify-content-center text-center">
                  <div className="col-6  justify-content-center text-center">
                    <a className="btn btn-action-light-mobile" href={`${process.env.PUBLIC_URL}/login`}>
                      {"Login"}
                    </a>
                  </div>
                  <div className="col-6  justify-content-center text-center">
                    <a className="btn btn-action-mobile" href={`${process.env.PUBLIC_URL}/signup`}>
                      Sign Up
                    </a>
                  </div>
                </div>{" "}
              </div>
            )} */}
          </div>
        </div>
        {subMenu ? (
          <div className="page-main-header-sub-mobile" style={{ top: "60px", height: "fit-content !important" }}>
            <div className="col-12 ">
              <div className="row ">
                <div className="col-12 d-flex flex-column justify-content-center text-center">
                  <a
                    className="m-t-20 m-b-20"
                    onClick={() => {
                      setSubMenu(false);
                    }}
                    href={`${process.env.PUBLIC_URL}/`}
                    style={{ cursor: "pointer", fontSize: "18px", color: "#6e6e80" }}
                  >
                    Home
                  </a>
                  {currentUser !== null && (
                    <a
                      className="m-b-20"
                      onClick={() => {
                        setSubMenu(false);
                      }}
                      href={`${process.env.PUBLIC_URL}/profile`}
                      style={{ cursor: "pointer", fontSize: "18px", color: "#6e6e80" }}
                    >
                      My Account
                    </a>
                  )}

                  {currentUser !== null ? (
                    <button
                      onClick={() => {
                        Logout_From_Firebase();
                      }}
                      className="m-b-20 m-l-50 m-r-50  btn-action-light-mobile"
                      href={`${process.env.PUBLIC_URL}/profile`}
                    >
                      Log Out
                    </button>
                  ) : (
                    <Fragment>
                      <a className="m-b-20 m-l-50 m-r-50 btn btn-action-light-mobile" href={`${process.env.PUBLIC_URL}/login`}>
                        {"Login"}
                      </a>
                      <a className="m-b-20 m-l-50 m-r-50 btn btn-action-mobile" href={`${process.env.PUBLIC_URL}/signup`}>
                        Create Account
                      </a>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>{" "}
          </div>
        ) : (
          <div></div>
        )}
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div className="page-main-header">
          <div className="main-header-right row justify-content-between">
            <div className="col-3">
              <a href={`${process.env.PUBLIC_URL}/`} style={{ cursor: "pointer", color: "#101010" }}>
                {/* <strong>Write</strong>bot.io */}
                <img src={WriteBotLogo} alt="logo" style={{ height: "40px" }} />
              </a>
            </div>
            <div className="col-5 ">
              <div className="row d-flex justify-content-center text-center">
                <div className="col-12 d-flex justify-content-center text-center">
                  <a href={`${process.env.PUBLIC_URL}/`} style={{ fontWeight: "bold", cursor: "pointer", fontSize: "18px", color: "#000" }}>
                    <FontAwesomeIcon className="m-r-10" icon={faHome} />
                    Home
                  </a>

                  {currentUser !== null && (
                    <a className="m-l-50" href={`${process.env.PUBLIC_URL}/profile`} style={{ fontWeight: "bold", cursor: "pointer", fontSize: "18px", color: "#000" }}>
                      <FontAwesomeIcon className="m-r-10" icon={faUser} />
                      My Account
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="row d-flex justify-content-center text-center">
                {/* <div className="col-2 d-flex justify-content-center text-center">
                  <h6>3 credits</h6>
                </div>
                <div className="col-2 d-flex justify-content-center text-center">
                  <button className="btn btn-primay" onClick={() => {}}>
                    Buy
                  </button>
                </div> */}

                {/* LogOut  or SignIn*/}
                {currentUser !== null ? (
                  <div className="col-4 col-xl-2 d-flex justify-content-center text-center">
                    <button className="btn btn-action-light" onClick={Logout_From_Firebase}>
                      Log Out
                    </button>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-6  justify-content-center text-center">
                      <a className="btn btn-action-light" style={{ color: "#2a3142" }} href={`${process.env.PUBLIC_URL}/login`}>
                        {"Login"}
                      </a>
                    </div>
                    <div className="col-6  justify-content-center text-center">
                      <a className="btn btn-action" style={{ color: "#2a3142" }} href={`${process.env.PUBLIC_URL}/signup`}>
                        Create Account
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
};
export default Header;
