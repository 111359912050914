import React, { Fragment } from "react";
import Header from "./common/header-component/header";
import Footer from "./common/footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackgroundImage from "../assets/writebot/contact_globe.svg";
// import Loader from "./common/loader";

const AppLayout = (props) => {
  return (
    <Fragment>
      {/* <Loader /> */}
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          <Header />
          {/* <Sidebar />
          <RightSidebar /> */}
          <div className="p-t-20 page-body">
            <div>{props.children}</div>
          </div>
          <div className="wave">
            <img src={BackgroundImage} alt="" style={{ widht: "100%" }} />
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default AppLayout;
