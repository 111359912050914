import React, { Fragment, Component } from "react";
import { firebase_app } from "../../../../data/config";
import { getMyRequests, getSpecificRequest } from "../../../../firebase-postGenerator/firebase-frontend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
import Editor from "../../viewer/ViewerText";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import CarouselPexel from "./../../carouselPexel/CarouselPexel";
import { toast } from "react-toastify";

class Usage extends Component {
  state = {
    menuRequestsOpen: false,
    requests: [],
    html: "",
    activePage: 1,
    recordsPerPage: 10,
    nPages: 1,
    pageNumbers: [1],
    idDocument: null,
  };

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        getMyRequests()
          .then((r) => {
            r.sort(function (a, b) {
              return b.date - a.date;
            });

            // Calcul nombre de page :
            const nPages = Math.ceil(r.length / this.state.recordsPerPage);
            const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

            this.setState({ requests: r, nPages, pageNumbers });
          })
          .catch(() => {});
      }
    });
  };

  sendVerificationEmail = async () => {
    await firebase_app.auth().currentUser.reload();
    const user = firebase_app.auth().currentUser;

    if (user) {
      if (user.emailVerified) {
        this.setState({ verifiedUser: user.emailVerified });
      } else {
        user
          .sendEmailVerification({
            url: "https://app.writebot.io/",
          })
          .then(() => {
            toast.info("Verification e-mail sent", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          })
          .catch((err) => {
            console.log(err);
            toast.error("Email already sent - check your spam or try later", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    }
  };

  Logout_From_Firebase = () => {
    firebase_app.auth().signOut();
  };

  handleMenuRequest = () => {
    this.setState({ menuRequestsOpen: !this.state.menuRequestsOpen });
  };
  handleClickUniqueRequest = (e) => {
    const state = e.state;
    const id = e.idDocument;
    if (state === "success") {
      getSpecificRequest({ id: id }).then((res) => {
        this.setState({ html: res.html, image: res.image, idDocument: id });
      });
    }
  };

  render() {
    return (
      <Fragment>
        <div className="card m-t-10">
          <div className="container">
            <div className="col-sm-12 col-xl-12">
              <div className="card-body ">
                <h4>
                  <strong>Historic usage</strong>
                </h4>
                <p>Browse through your past requests and display them again</p>

                {/* MENU FOR POST BLOG */}
                <div
                  onClick={this.handleMenuRequest}
                  className="p-b-10 p-t-10 m-t-20"
                  style={{ cursor: "pointer", borderTopStyle: "solid", borderTopColor: "#ececf1", borderTopWidth: "1.5px", borderBottomStyle: "solid", borderBottomColor: "#ececf1", borderBottomWidth: "1.5px" }}
                >
                  <div className="row">
                    <div className={isMobile ? "col-8" : "col-5"}>
                      <div>
                        <span className="m-r-10">
                          <strong>Generated Requests</strong>
                        </span>{" "}
                        <span style={{ color: "#8e8ea0" }}>{` ${this.state.requests.length} requests`}</span>
                      </div>
                    </div>
                    <div className={isMobile ? "" : "col-6"}></div>
                    <div className="col-1 my-auto">{this.state.menuRequestsOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</div>
                  </div>
                </div>

                {/* Map Requests */}
                {this.state.menuRequestsOpen && this.state.requests.length > 0 ? (
                  <div>
                    {this.state.requests.map((e, index) => {
                      const startElement = this.state.recordsPerPage * (this.state.activePage - 1);
                      const lastElement = this.state.recordsPerPage * this.state.activePage;
                      if (index >= startElement && index <= lastElement) {
                        return (
                          <div
                            onClick={() => this.handleClickUniqueRequest({ idDocument: e.idDocument, state: e.state })}
                            className={e.idDocument === this.state.idDocument ? "historicList row p-t-10 p-b-10 historicList-active" : "historicList row p-t-10 p-b-10 "}
                            key={e.idDocument}
                          >
                            {/* <div className="col-2">
                          <strong>{`ID - ${e.id}`}</strong>
                        </div> */}
                            <div className={isMobile ? "col-9" : "col-5"}>
                              <strong>{e.idea.length > 50 ? e.idea.slice(0, 45) + "[...]" : e.idea}</strong>
                            </div>
                            {isMobile ? <div></div> : <div className="col-3">{`${e.credit} credit(s)`}</div>}

                            <div className={isMobile ? "col-3" : "col-1"}>
                              <div className={e.state === "fail" ? "notVerifiedUser" : "verifiedUser"}>{e.state}</div>
                            </div>
                          </div>
                        );
                      }
                    })}

                    {/* Pagination  */}
                    <div className="row mx-auto align-items-center justify-content-md-center m-t-20">
                      <Pagination aria-label="Page navigation example" className="pagination-primary">
                        <PaginationItem disabled={this.state.activePage === 1}>
                          <PaginationLink
                            onClick={() => {
                              this.setState({ activePage: 1 });
                            }}
                            first
                            href="#javascript"
                          />
                        </PaginationItem>
                        <PaginationItem disabled={this.state.activePage === 1}>
                          <PaginationLink
                            onClick={() => {
                              this.setState({ activePage: this.state.activePage - 1 });
                            }}
                            previous
                            href="#javascript"
                          />
                        </PaginationItem>

                        {this.state.pageNumbers.map((e) => {
                          return (
                            <PaginationItem key={e} active={e === this.state.activePage}>
                              <PaginationLink
                                onClick={() => {
                                  this.setState({ activePage: e });
                                }}
                                href="#javascript"
                              >
                                {e}
                              </PaginationLink>
                            </PaginationItem>
                          );
                        })}

                        <PaginationItem disabled={this.state.activePage === this.state.pageNumbers.length}>
                          <PaginationLink
                            onClick={() => {
                              this.setState({ activePage: this.state.activePage + 1 });
                            }}
                            next
                            href="#javascript"
                          />
                        </PaginationItem>
                        <PaginationItem disabled={this.state.activePage === this.state.pageNumbers.length}>
                          <PaginationLink
                            onClick={() => {
                              this.setState({ activePage: this.state.pageNumbers.length });
                            }}
                            last
                            href="#javascript"
                          />
                        </PaginationItem>
                      </Pagination>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                {this.state.menuRequestsOpen && this.state.requests.length === 0 ? (
                  <div className="row m-t-10 m-b-10" style={{ borderBottomStyle: "solid", borderBottomColor: "#ececf1", borderBottomWidth: "1.5px" }}>
                    <div className="col">No data available</div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>

            {/* <div style={{ marginLeft: "-15px", marginRight: "-15px" }}>{this.state.html.length > 0 ? <Viewer2 data={{ myPost: this.state.html, isLoading: false, displayTitle: false }} /> : <div></div>}</div> */}
          </div>
        </div>
        {this.state.html.length > 1 ? (
          <div>
            {this.state.image.length > 0 && <CarouselPexel data={{ image: this.state.image }} />}
            <Editor data={{ html: this.state.html, idDocument: this.state.idDocument }} />
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

export default Usage;
