import React, { Fragment, useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { firebase_app, googleProvider } from "../data/config";

import { ToastContainer, toast } from "react-toastify";
import GoogleLogo from "./googleIcon.svg";
import logoWriteBot from "../assets/writebot/WriteBotLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
// import disposableMail from "../assets/json/disposableDomain.json";

const Signup = () => {
  const history = useHistory();
  // let { affiliateID } = useParams();

  const [currentUser, setCurrentUser] = useState(null);
  const [inApp, setInApp] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  useEffect(() => {
    setInApp(isFacebookApp());
  }, []);

  function isFacebookApp() {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const { email, password } = e.target.elements;

    const domain = email.value.split("@")[1];

    // if (disposableMail.includes(domain)) {
    //   setTimeout(() => {
    //     toast.error("This email provider is forbidden");
    //   }, 200);

    //   return null;
    // }

    try {
      firebase_app
        .auth()
        .createUserWithEmailAndPassword(email.value, password.value)
        .then((userCredential) => {
          userCredential.user.sendEmailVerification({
            url: "https://app.writebot.io/",
          });
          toast.info("Verification e-mail sent", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            history.push(`${process.env.PUBLIC_URL}/`);
          }, 2000);
        })
        .catch((err) => {
          setTimeout(() => {
            toast.error(err.message);
          }, 200);
        });
    } catch (error) {
      console.log("Erreur");

      // alert(error);
    }
  };

  if (currentUser) {
    return <Redirect to="/" />;
  }

  const SignInWithGoogle = () => {
    if (inApp) {
      setTimeout(() => {
        toast.error("Authentication with Google not allowed within the Facebook/Messenger/Instagram app. Open the site in your default browser.");
      }, 200);
    } else {
      firebase_app
        .auth()
        .signInWithPopup(googleProvider)
        .then(() => {
          setTimeout(() => {
            history.push(`${process.env.PUBLIC_URL}/`);
          }, 200);

          setCurrentUser(true);
        })
        .catch((err) => {});

      if (currentUser) {
        return <Redirect to="/" />;
      }
    }
  };
  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          {/* <!-- sign up page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-sm-12 p-0">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <div className="text-center">
                        <img src={logoWriteBot} alt="Main Logo" style={{ height: "80px" }} />
                      </div>
                    </div>
                    <div className="card mt-4 p-4">
                      <h4 className="text-center">{"Create Account"}</h4>
                      <h6 className="text-center">{"Enter a valid email and password to register"}</h6>

                      <form className="theme-form" onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label for="email" className="col-form-label">
                            {"E-mail"}
                          </label>
                          <input className="form-control" type="email" name="email" placeholder="email@email.com" />
                        </div>
                        <div className="form-group">
                          <label for="password" className="col-form-label">
                            {"Password"}
                          </label>
                          <div style={{ display: "flex", position: "relative" }}>
                            <input type={passwordShown ? "text" : "password"} className="form-control" name="password" placeholder="**********" />

                            <FontAwesomeIcon onClick={togglePasswordVisiblity} icon={faEye} style={{ position: "absolute", top: "30%", right: "4%" }} />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-sm-5">
                            <button className="btn-action" type="submit">
                              {"Create Account"}
                            </button>
                          </div>

                          <div className="col-sm-7">
                            <a className="btn-action-light text-capitalize" href={`${process.env.PUBLIC_URL}/login`}>
                              {"Already member ? Login"}
                            </a>
                          </div>
                        </div>
                        <div className="form-divider"></div>
                        <div className="social mt-3">
                          <div className="form-group btn-showcase d-flex">
                            <button className="btn btn-action-light" type="button" onClick={SignInWithGoogle}>
                              <img src={GoogleLogo} alt="Logo Google" style={{ height: "20px" }} />
                              {/* <i className="fa fa-google"> </i> */}
                              {"  Continue with Google"}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- sign up page ends--> */}
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default Signup;
