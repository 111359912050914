import React, { Component, Fragment } from "react";

import laptopInvest from "../../../assets/images/isometric/SEOImageIso.jpg";
import livestrong from "./logo/livestrong.png";
import mfp from "./logo/mfp.png";
import farout from "./logo/farout.png";
import outside from "./logo/outside.png";
import { isMobile } from "react-device-detect";
import ChartCompare from "./chartCompare";

class CatchPhrase extends Component {
  state = {};
  render() {
    return (
      <Fragment>
        <div className="card m-t-10">
          <div className="container">
            <div className="col-sm-12 col-xl-12">
              <div className="card-body ">
                <div className="row">
                  <div className="col my-auto" style={{ textAlign: "center" }}>
                    <h5 style={{ fontSize: "26px", lineHeight: "40px" }}>{"Create quality content with the latest AI technology at the lowest prices!"}</h5>
                    {/* <button className="m-t-20 btn ">Start Now</button> */}
                    <div className="m-50"></div>
                    {/* <div className="wrapper">
                      <div className="typing-demo">Writebot</div>
                    </div> */}
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <img src={laptopInvest} alt="Laptop" style={{ width: "100%" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card m-t-10">
          <div className="container">
            <div className="col-sm-12 col-xl-12">
              <div className="card-body ">
                <div className="row">
                  <div className="col my-auto" style={{ textAlign: "center" }}>
                    <h5 style={{ fontSize: "16px", fontWeight: "800" }}>{"Limited offer"}</h5>
                    <h5 style={{ fontSize: "16px" }}>{"Up to 25 000 words of content for only $4.90! "}</h5>
                    <h5 style={{ fontSize: "16px" }}>{"No subscriptions, available for life"}</h5>

                    {/* <ChartCompare /> */}
                    <a className="btn-action m-t-20" href={`${process.env.PUBLIC_URL}/profile`}>
                      Get Credits
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <h5 style={{ fontSize: "20px", lineHeight: "40px" }} className="m-t-20 m-b-20">
            We work with...
          </h5>
          <div className="row">
            <div className={isMobile ? "col-12" : "col-3"}>
              <div className="card">
                <div className={isMobile ? "text-center" : "m-t-10 m-b-10 text-center"}>
                  <img src={farout} height="80px" />
                </div>
              </div>
            </div>

            <div className={isMobile ? "col-12" : "col-3"}>
              <div className="card">
                <div className={isMobile ? "text-center" : "m-t-10 m-b-10 text-center"}>
                  <img src={livestrong} height="80px" width="" />
                </div>
              </div>
            </div>

            <div className={isMobile ? "col-12" : "col-3"}>
              <div className="card">
                <div className={isMobile ? "text-center" : "m-t-10 m-b-10 text-center"}>
                  <img src={mfp} height="80px" />
                </div>
              </div>
            </div>

            <div className={isMobile ? "col-12" : "col-3"}>
              <div className="card">
                <div className={isMobile ? "text-center" : "m-t-10 m-b-10 text-center"}>
                  <img src={outside} height="80px" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <h5 style={{ fontSize: "20px", lineHeight: "40px" }} className="m-t-20 m-b-20">
            Discover all possibilities ...
          </h5>{" "}
        </div>
      </Fragment>
    );
  }
}

export default CatchPhrase;
