import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCopyright, faLightbulb, faStar } from "@fortawesome/fontawesome-free-regular";
import { faPenToSquare, faScaleBalanced, faCheckCircle, faGlobe, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faGoogle, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";

class SelectionProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      select: 1,
    };
  }

  onSubmit = (e) => {
    this.props.handleClick(e);
    this.setState({ select: e });
  };

  render() {
    return (
      <Fragment>
        <div className="container">
          <div className="row justify-content-md-center align-items-center">
            {/* <h3 className="text-center">Create, Compose and Sell...</h3> */}
            <div className="card-deck">
              {/* Product BrandName  */}
              <div
                className="col-sm-12 col-md-4 d-flex flex-column justify-content-center text-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.onSubmit(6);
                }}
              >
                <div className={this.state.select === 6 ? "card m-t-20 card-product card-selected" : "card m-t-20 card-product"}>
                  <div style={{ position: "absolute", right: "20px", top: "10px" }}>
                    <FontAwesomeIcon icon={this.state.select === 6 ? faCheckCircle : faCircle} style={this.state.select === 6 ? { color: "#1a7f64" } : {}} size="lg" />
                  </div>
                  <div className="card-body text-center ">
                    <div className="containerIconProductGreen">
                      <FontAwesomeIcon icon={faCopyright} className="iconProduct" size="lg" />
                    </div>
                    {/* <img src={IconBrandProduct} className="iconProduct" /> */}
                    <h6 className="m-t-10">Product and Brand Name Ideas</h6>

                    <div className="m-20"></div>
                    <div>
                      <p>Generate 30 names for your product/company and come up with a memorable tagline!</p>
                    </div>
                    {/* <div className="m-10"></div> */}
                  </div>
                </div>
              </div>

              {/* Product Web Brand Name */}
              <div
                className="col-sm-12 col-md-4 d-flex flex-column justify-content-center text-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.onSubmit(4);
                }}
              >
                <div className={this.state.select === 4 ? "card m-t-20 card-product card-selected" : "card m-t-20 card-product"}>
                  <div style={{ position: "absolute", right: "20px", top: "10px" }}>
                    <FontAwesomeIcon icon={this.state.select === 4 ? faCheckCircle : faCircle} style={this.state.select === 4 ? { color: "#1a7f64" } : {}} size="lg" />
                  </div>
                  <div className="card-body text-center ">
                    <div className="containerIconProductGreen">
                      <FontAwesomeIcon icon={faGlobe} className="iconProduct" size="lg" />
                    </div>
                    {/* <img src={IconWebProduct} className="iconProduct" /> */}
                    <h6 className="m-t-10">Available Website name idea</h6>

                    <div className="m-20"></div>
                    <div>
                      <p>Create the perfect Brandname for your website and check the availability</p>
                    </div>
                    {/* <div className="m-10"></div> */}
                  </div>
                </div>
              </div>

              {/* benchMark  */}
              <div
                className="col-sm-12 col-md-4 d-flex flex-column justify-content-center text-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.onSubmit(5);
                }}
              >
                <div className={this.state.select === 5 ? "card m-t-20 card-product card-selected" : "card m-t-20 card-product"}>
                  <div style={{ position: "absolute", right: "20px", top: "10px" }}>
                    <FontAwesomeIcon icon={this.state.select === 5 ? faCheckCircle : faCircle} style={this.state.select === 5 ? { color: "#1a7f64" } : {}} size="lg" />
                  </div>
                  <div className="card-body text-center ">
                    <div className="containerIconProductGreen">
                      <FontAwesomeIcon icon={faScaleBalanced} className="iconProduct" />
                    </div>
                    {/* <img src={IconProductBenchMark} className="iconProduct" /> */}
                    <h6 className="m-t-10">Market Analysis</h6>

                    <div className="m-20"></div>
                    <div>
                      <p>A fast and complete benchmark about your business </p>
                    </div>
                    {/* <div className="m-10"></div> */}
                  </div>
                </div>
              </div>

              {/* Product 1  */}
              <div
                className="col-sm-12 col-md-4 d-flex flex-column justify-content-center text-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.onSubmit(1);
                }}
              >
                <div className={this.state.select === 1 ? "card m-t-20 card-product card-selected" : "card m-t-20 card-product"}>
                  <div style={{ position: "absolute", right: "20px", top: "10px" }}>
                    <FontAwesomeIcon icon={this.state.select === 1 ? faCheckCircle : faCircle} style={this.state.select === 1 ? { color: "#1a7f64" } : {}} size="lg" />
                  </div>
                  <div className="card-body text-center ">
                    <div className="containerIconProductBlue">
                      <FontAwesomeIcon icon={faPenToSquare} className="iconProduct" />
                    </div>

                    <h6 className="m-t-10">SEO Friendly - Blog Article</h6>

                    <div className="m-20"></div>
                    <div>
                      <p>
                        Attract more traffic and improve your blog's search ranking by creating <strong>a unique post</strong> based on your idea.
                      </p>
                    </div>
                    {/* <div className="m-10"></div> */}
                  </div>
                </div>
              </div>

              {/* Product 2  */}
              <div
                className="col-sm-12 col-md-4 d-flex flex-column justify-content-center text-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.onSubmit(2);
                }}
              >
                <div className={this.state.select === 2 ? "card m-t-20 card-product card-selected" : "card m-t-20 card-product"}>
                  <div style={{ position: "absolute", right: "20px", top: "10px" }}>
                    <FontAwesomeIcon icon={this.state.select === 2 ? faCheckCircle : faCircle} style={this.state.select === 2 ? { color: "#1a7f64" } : {}} size="lg" />
                  </div>
                  <div className="card-body text-center ">
                    <div className="containerIconProductBlue">
                      <FontAwesomeIcon icon={faLightbulb} className="iconProduct" />
                    </div>
                    <h6 className="m-t-10">20 Ideas for Blog Article</h6>

                    <div className="m-20"></div>
                    <div>
                      <p>
                        Come up with <strong>20 content ideas</strong> that fit the theme of your blog with an SEO Score.
                      </p>
                    </div>
                    <div className="m-30"></div>
                  </div>
                </div>
              </div>

              {/* Product 3  */}
              <div
                className="col-sm-12 col-md-4 d-flex flex-column justify-content-center text-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.onSubmit(3);
                }}
              >
                <div className={this.state.select === 3 ? "card m-t-20 card-product card-selected" : "card m-t-20 card-product"}>
                  <div style={{ position: "absolute", right: "20px", top: "10px" }}>
                    <FontAwesomeIcon icon={this.state.select === 3 ? faCheckCircle : faCircle} style={this.state.select === 3 ? { color: "#1a7f64" } : {}} size="lg" />
                  </div>
                  <div className="card-body text-center ">
                    <div className="containerIconProductBlue">
                      <FontAwesomeIcon icon={faStar} className="iconProduct" />
                    </div>
                    <h6 className="m-t-10"> Selling product description</h6>

                    <div className="m-20"></div>
                    <div>
                      <p>Create a Captivating Tagline to Boost Sales for Your Product!</p>
                    </div>
                    {/* <div className="m-10"></div> */}
                  </div>
                </div>
              </div>

              {/* Product marketing  */}

              {/* Product 7 */}
              <div
                className="col-sm-12 col-md-4 d-flex flex-column justify-content-center text-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.onSubmit(7);
                }}
              >
                <div className={this.state.select === 7 ? "card m-t-20 card-product card-selected" : "card m-t-20 card-product"}>
                  <div style={{ position: "absolute", right: "20px", top: "10px" }}>
                    <FontAwesomeIcon icon={this.state.select === 7 ? faCheckCircle : faCircle} style={this.state.select === 7 ? { color: "#1a7f64" } : {}} size="lg" />
                  </div>
                  <div className="card-body text-center ">
                    <div className="containerIconProductYellow">
                      <FontAwesomeIcon icon={faFacebook} className="iconProduct" />
                    </div>
                    <h6 className="m-t-10">High Conversion Facebook Ads</h6>
                    <div></div>
                    <div className="m-20"></div>
                    <div>
                      <p>Create a Captivating Ads to Boost Sales for Your Product and get the potential target group!</p>
                    </div>
                    {/* <div className="m-10"></div> */}
                  </div>
                </div>
              </div>
              {/* Product 8 */}
              <div
                className="col-sm-12 col-md-4 d-flex flex-column justify-content-center text-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.onSubmit(8);
                }}
              >
                <div className={this.state.select === 8 ? "card m-t-20 card-product card-selected" : "card m-t-20 card-product"}>
                  <div style={{ position: "absolute", right: "20px", top: "10px" }}>
                    <FontAwesomeIcon icon={this.state.select === 8 ? faCheckCircle : faCircle} style={this.state.select === 8 ? { color: "#1a7f64" } : {}} size="lg" />
                  </div>
                  <div className="card-body text-center ">
                    <div className="containerIconProductYellow">
                      <FontAwesomeIcon icon={faGoogle} className="iconProduct" />
                    </div>
                    <h6 className="m-t-10">Perfect Google Ads</h6>

                    <div className="m-20"></div>
                    <div>
                      <p>Create a Captivating Ads to Boost Sales on Google and get Keywords proposition!</p>
                    </div>
                    {/* <div className="m-10"></div> */}
                  </div>
                </div>
              </div>
              {/* Product 9 */}
              <div
                className="col-sm-12 col-md-4 d-flex flex-column justify-content-center text-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.onSubmit(9);
                }}
              >
                <div className={this.state.select === 9 ? "card m-t-20 card-product card-selected" : "card m-t-20 card-product"}>
                  <div style={{ position: "absolute", right: "20px", top: "10px" }}>
                    <FontAwesomeIcon icon={this.state.select === 9 ? faCheckCircle : faCircle} style={this.state.select === 9 ? { color: "#1a7f64" } : {}} size="lg" />
                  </div>
                  <div className="card-body text-center ">
                    <div className="containerIconProductYellow">
                      <FontAwesomeIcon icon={faEnvelope} className="iconProduct" />
                    </div>
                    <h6 className="m-t-10">Perfect Marketing Email Speech</h6>

                    <div className="m-20"></div>
                    <div>
                      <p>Create a Captivating Tagline to Boost Sales for Your Product!</p>
                    </div>
                    {/* <div className="m-10"></div> */}
                  </div>
                </div>
              </div>

              {/* Product 10 */}
              <div
                className="col-sm-12 col-md-4 d-flex flex-column justify-content-center text-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.onSubmit(10);
                }}
              >
                <div className={this.state.select === 10 ? "card m-t-20 card-product card-selected" : "card m-t-20 card-product"}>
                  <div style={{ position: "absolute", right: "20px", top: "10px" }}>
                    <FontAwesomeIcon icon={this.state.select === 10 ? faCheckCircle : faCircle} style={this.state.select === 9 ? { color: "#1a7f64" } : {}} size="lg" />
                  </div>
                  <div className="card-body text-center ">
                    <div className="containerIconProductYellow">
                      <FontAwesomeIcon icon={faInstagram} className="iconProduct" />
                    </div>
                    <h6 className="m-t-10">Instragram Post Description</h6>

                    <div className="m-20"></div>
                    <div>
                      <p>Create a Captivating Description to Boost Engagement!</p>
                    </div>
                    {/* <div className="m-10"></div> */}
                  </div>
                </div>
              </div>
              {/* Product 11 */}
              <div
                className="col-sm-12 col-md-4 d-flex flex-column justify-content-center text-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.onSubmit(11);
                }}
              >
                <div className={this.state.select === 11 ? "card m-t-20 card-product card-selected" : "card m-t-20 card-product"}>
                  <div style={{ position: "absolute", right: "20px", top: "10px" }}>
                    <FontAwesomeIcon icon={this.state.select === 11 ? faCheckCircle : faCircle} style={this.state.select === 9 ? { color: "#1a7f64" } : {}} size="lg" />
                  </div>
                  <div className="card-body text-center ">
                    <div className="containerIconProductYellow">
                      <FontAwesomeIcon icon={faTwitter} className="iconProduct" />
                    </div>
                    <h6 className="m-t-10">Twitter Post</h6>

                    <div className="m-20"></div>
                    <div>
                      <p>Create Captivating Tweets to Boost Engagement!</p>
                    </div>
                    {/* <div className="m-10"></div> */}
                  </div>
                </div>
              </div>

              {/* Product 12 */}
              <div
                className="col-sm-12 col-md-4 d-flex flex-column justify-content-center text-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.onSubmit(12);
                }}
              >
                <div className={this.state.select === 12 ? "card m-t-20 card-product card-selected" : "card m-t-20 card-product"}>
                  <div style={{ position: "absolute", right: "20px", top: "10px" }}>
                    <FontAwesomeIcon icon={this.state.select === 12 ? faCheckCircle : faCircle} style={this.state.select === 9 ? { color: "#1a7f64" } : {}} size="lg" />
                  </div>
                  <div className="card-body text-center ">
                    <div className="containerIconProductYellow">
                      <FontAwesomeIcon icon={faTwitter} className="iconProduct" />
                    </div>
                    <h6 className="m-t-10">Twitter Thread</h6>

                    <div className="m-20"></div>
                    <div>
                      <p>Create Thread to Boost Engagement!</p>
                    </div>
                    {/* <div className="m-10"></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="m-50"></div>
        </div>
      </Fragment>
    );
  }
}

export default SelectionProduct;
