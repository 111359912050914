import { $getNodeByKey } from "lexical";
import { $generateNodesFromDOM, $generateHtmlFromNodes } from "@lexical/html";
import { firebase_app } from "../../../../data/config";
import { toast } from "react-toastify";

const firebaseEditor = async ({ text, type }) => {
  const user = firebase_app.auth().currentUser;

  if (user) {
    switch (type) {
      case "reformulate":
        const reformulateText = firebase_app.functions().httpsCallable("reformulateText");
        const reformulation = await reformulateText({ text: text, uid: user.uid });

        return reformulation.data.res;
      case "outline":
        const createOutline = firebase_app.functions().httpsCallable("createOutline");
        const outline = await createOutline({ text: text, uid: user.uid });

        return outline.data.res;
      case "content":
        const createContent = firebase_app.functions().httpsCallable("createContent");
        const content = await createContent({ text: text, uid: user.uid });
        return content.data.res;
    }
  } else {
    return text;
  }
};

const getText = (selection) => {
  // Return title for context ?
  let anchor = selection.anchor;
  let focus = selection.focus;
  if (selection.isBackward()) {
    anchor = selection.focus;
    focus = selection.anchor;
  }
  const anchorKey = anchor.key;
  const focusKey = focus.key;
  const start = anchor.offset;
  const end = focus.offset;
  let selectedText = "";

  const mapBlock = selection._cachedNodes;
  if (focusKey === anchorKey) {
    selectedText = $getNodeByKey(anchorKey).__text.slice(start, end);
  } else {
    let text = "";
    let save = false;
    let txtTmp = "";

    mapBlock.forEach((e) => {
      // txtTmp = currentContent.getBlockForKey(e.key).getText();

      if ("__text" in e) {
        txtTmp = e.__text;
        if (e.__key === anchorKey) {
          save = true;
          text = text + " " + txtTmp.slice(start, txtTmp.length);
        }
        if (save && e.__key !== focusKey && e.__key !== anchorKey) {
          text = text + " " + txtTmp;
        }
        if (e.__key === focusKey) {
          save = false;
          text = text + " " + txtTmp.slice(0, end);
        }
      }
    });
  }

  return { selectedText, anchorKey, focusKey };
};

export const copyData = (editor) => {
  editor.update(() => {
    let htmlString = $generateHtmlFromNodes(editor, null);

    const elementToReplace = [
      "<span>",
      "</span>",
      ' class="editor-heading-h1"',
      ' class="editor-heading-h2"',
      ' class="editor-list-ul"',
      ' class="editor-listitem"',
      ' class="editor-heading-h3"',
      ' class="editor-link"',
      ' class="editor-text-bold"',
      ' class="editor-text-bold editor-text-italic"',
      ' class="editor-text-italic"',
      ' class="editor-heading-h4"',
      ' class="editor-paragraph"',
      ' class="editor-quote"',
      ' class="editor-code" spellcheck="false"',
      ' class="editor-list-ol"',
      //   "<br>",
      'dir="ltr"',
    ];
    elementToReplace.forEach((e) => {
      htmlString = htmlString.replaceAll(e, "");
    });

    navigator.clipboard.writeText(htmlString);
    setTimeout(() => {
      toast.success("HTML Copy in Clipboard");
    }, 200);
  });
};

export const getProperHtml = (note) => {
  // Verification si format code

  // Verification si format HTML
  const parser = new DOMParser();
  let dom = parser.parseFromString(note, "text/html");
  const body = dom.querySelector("body");
  // Handle missing <p> - A améliorer si lien dans le texte
  let textNodes = Array.from(body.childNodes);
  let textCorrected = document.createElement("body");

  try {
    // First filtre
    let textNodesTmp = [];
    let tmpIndex = "";
    let encours = false;
    let paragraph = "";
    for (let i = 0; i < textNodes.length; i++) {
      if (textNodes[i].nodeName === "#text") {
        if (encours === false) {
          tmpIndex = i;
          encours = true;
          paragraph = document.createElement("p");
          paragraph.innerHTML = textNodes[i].textContent;
        } else {
          paragraph.innerHTML = paragraph.innerHTML + textNodes[i].textContent;
        }
      } else {
        if (encours) {
          if (textNodes[i].tagName === "A") {
            paragraph.appendChild(textNodes[i]);
          } else {
            textNodesTmp.push(paragraph);
            textNodesTmp.push(textNodes[i]);
            encours = false;
          }
        } else {
          encours = false;
          textNodesTmp.push(textNodes[i]);
        }
      }
    }

    // S'il y a encore un encours à la fin, alors on pousse "Paragraphe"
    if (encours) {
      textNodesTmp.push(paragraph);
    }

    textNodes = textNodesTmp;
  } catch {}

  textNodes.map((e) => {
    if ("tagName" in e) {
      // Pas de texte
      // Balise de style <b>
      //   if (e.innerText !== "") {
      if (e.tagName !== "BR" && e.tagName !== "B") {
        textCorrected.appendChild(e);
        return e;
      }
      if (e.tagName === "B") {
        const paragraph = document.createElement("p");
        paragraph.appendChild(e);
        textCorrected.appendChild(paragraph);
        return paragraph;
      }

      //   }
    } else {
      const paragraph = document.createElement("p");
      paragraph.innerHTML = e.textContent;
      paragraph.appendChild(e);

      textCorrected.appendChild(paragraph);
      return paragraph;
    }
  });

  dom.querySelector("html").innerHTML = textCorrected.innerHTML;

  return dom;
};

export const reformulateContent = (editor) => {
  // Work only for unique Node - verifier au niveau au dessus
  // Check minimal requirement

  const getReformulation = ({ selectedText, anchorKey, focusKey }) => {
    firebaseEditor({ text: selectedText, type: "reformulate" }).then((result) => {
      editor.update(() => {
        // const selection = editor._editorState._selection;
        // Get Text brut and position
        // const { selectedText, anchorKey, focusKey } = getText(selection);

        const nodeSelected = $getNodeByKey(focusKey);
        let textInitial = nodeSelected.getTextContent();
        textInitial = textInitial.replace(selectedText, result);
        nodeSelected.setTextContent(textInitial);

        let spaceBefore = "";
        let spaceAfter = "";

        if (selectedText[0] === " ") spaceBefore = " ";
        if (selectedText[selectedText.length - 1] === " ") spaceAfter = " ";
        textInitial = textInitial.replace(selectedText, spaceBefore + result + spaceAfter);
        nodeSelected.setTextContent(textInitial);
      });
    });
  };
  editor.update(() => {
    const selection = editor._editorState._selection;
    // Get Text brut and position
    const { selectedText, anchorKey, focusKey } = getText(selection);

    getReformulation({ selectedText, anchorKey, focusKey });
    setTimeout(() => {
      toast.info("Operation in progress, please wait !");
    }, 200);
  });
};

export const generateOutline = (editor) => {
  // Work only for unique Node - verifier au niveau au dessus
  // Check minimal requirement

  const getOutline = ({ selectedText, anchorKey, focusKey }) => {
    firebaseEditor({ text: selectedText, type: "outline" }).then((result) => {
      editor.update(() => {
        const nodeSelected = $getNodeByKey(focusKey);
        const dom = getProperHtml(result);
        let newNodes = $generateNodesFromDOM(editor, dom);
        newNodes.reverse();
        newNodes.forEach((e) => {
          nodeSelected.insertAfter(e);
        });
      });
    });
  };
  editor.update(() => {
    const selection = editor._editorState._selection;

    const { selectedText, anchorKey, focusKey } = getText(selection);
    getOutline({ selectedText, anchorKey, focusKey });
    setTimeout(() => {
      toast.info("Operation in progress, please wait !");
    }, 200);
  });
};

export const generateContent = (editor) => {
  // Get the context
  const getContent = ({ selectedText, anchorKey, focusKey }) => {
    firebaseEditor({ text: selectedText, type: "content" }).then((result) => {
      editor.update(() => {
        const nodeSelected = $getNodeByKey(focusKey);
        const dom = getProperHtml(result);
        let newNodes = $generateNodesFromDOM(editor, dom);
        newNodes.reverse();
        newNodes.forEach((e) => {
          nodeSelected.insertAfter(e);
        });
      });
    });
  };
  editor.update(() => {
    const selection = editor._editorState._selection;

    const { selectedText, anchorKey, focusKey } = getText(selection);
    // get title
    getContent({ selectedText, anchorKey, focusKey });
    setTimeout(() => {
      toast.info("Operation in progress, please wait !");
    }, 200);
  });
};

export const saveInFirebase = ({ editor, id, newDoc }) => {
  const user = firebase_app.auth().currentUser;

  if (user) {
    const saveTextToFirebase = firebase_app.functions().httpsCallable("saveTextToFirebase");

    editor.update(() => {
      let htmlString = $generateHtmlFromNodes(editor, null);
      const elementToReplace = [
        "<span>",
        "</span>",
        ' class="editor-heading-h1"',
        ' class="editor-heading-h2"',
        ' class="editor-list-ul"',
        ' class="editor-listitem"',
        ' class="editor-heading-h3"',
        ' class="editor-link"',
        ' class="editor-text-bold"',
        ' class="editor-text-bold editor-text-italic"',
        ' class="editor-text-italic"',
        ' class="editor-heading-h4"',
        ' class="editor-paragraph"',
        ' class="editor-quote"',
        ' class="editor-code" spellcheck="false"',
        ' class="editor-list-ol"',
        //   "<br>",
        'dir="ltr"',
      ];
      elementToReplace.forEach((e) => {
        htmlString = htmlString.replaceAll(e, "");
      });

      navigator.clipboard.writeText(htmlString);
      saveTextToFirebase({ id: id, uid: user.uid, html: htmlString, newDoc });
      setTimeout(() => {
        toast.success("Saved !");
      }, 200);
    });
  }
};
