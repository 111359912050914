export const languageAvailable = [
  "English",
  "French",
  "Spanish",
  "German",
  "Arabic",
  "Bulgarian",
  "Chinese (S)",
  "Chinese (T)",
  "Czech",
  "Danish",
  "Dutch",
  "Farsi",
  "Filipino",
  "Finnish",
  "Greek",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Indonesian",
  "Italian",
  "Japanese",
  "Korean",
  "Lithuanian",
  "Malay",
  "Norwegian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Slovak",
  "Slovenian",
  "Swedish",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Vietnamese",
];
