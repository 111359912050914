import { firebase_app } from "../data/config";

export const getCredit = async () => {
  const uid = firebase_app.auth().currentUser.uid;
  const verifiedUser = firebase_app.auth().currentUser.emailVerified;

  var credit = 0;
  var totCredit = 0;
  if (verifiedUser) {
    await firebase_app
      .firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          if (doc.data()["credit"] !== undefined) {
            credit = doc.data().credit;
            totCredit = doc.data().totCredit;
          }
        }
      })
      .catch();
  }

  return { verifiedUser, credit, totCredit };
};

export const getMyRequests = async () => {
  const uid = firebase_app.auth().currentUser.uid;
  const verifiedUser = firebase_app.auth().currentUser.emailVerified;

  var myRequests = [];

  if (verifiedUser) {
    await firebase_app
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("requests")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          myRequests.push({ ...doc.data(), idDocument: doc.id });
        });
      })
      .catch();
  }

  return myRequests;
};

export const getSpecificRequest = async (data) => {
  const id = data.id;
  const verifiedUser = firebase_app.auth().currentUser.emailVerified;
  var html = "";
  let image = [];

  if (verifiedUser) {
    await firebase_app
      .firestore()
      .collection("requests")
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          if (doc.data()["res"] !== undefined) {
            const dataFirebase = doc.data();

            html = dataFirebase.res;
            if ("image" in dataFirebase) {
              image = dataFirebase.image;
            }
          }
        }
      })
      .catch();
  }

  return { html, image };
};
