import React, { Component } from "react";
import Coverflow from "react-coverflow";

import { isMobile } from "react-device-detect";

class CarouselPexel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      image: this.props.data.image,
    };
  }

  render() {
    if (this.props.data.image === 0) {
      return <div></div>;
    }
    return (
      // Revoir width and height
      <div className="card">
        <div className="card-body">
          <h4 className="m-l-20 m-b-20">
            <strong>Image cover</strong> <sup style={{ color: "#9d2b2e" }}>BETA</sup>
          </h4>
          <p className="m-l-20 m-b-20">
            This gallery has been generated automatically based on your article idea. However, there might be some images <strong>which are not relevant</strong>. All the images in this gallery are <strong>free to use</strong> and
            are sourced from Pexels.com.
          </p>
          <p className="m-l-20 m-b-20">
            <strong>Click to be redirected to Pexels.com</strong>
          </p>
        </div>
        <Coverflow width={100} currentFigureScale={1.3} height={400} displayQuantityOfSide={isMobile ? 1 : 2} infiniteScroll={false} navigation={false} enableScroll={false} enableHeading={false} clickable={true}>
          {this.props.data.image.map((photos) => {
            return <img src={photos.preview} data-action={photos.url} key={photos.id} />;
          })}
        </Coverflow>
      </div>
    );
  }
}

export default CarouselPexel;
