import React, { Component, Fragment } from "react";
import LoaderWriteBot from "./loader";
import Editor from "../../viewer/ViewerText";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class Viewer2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myPost: this.props.data.myPost,
      isLoading: this.props.data.isLoading,
      idDocument: this.props.data.idDocument,
    };
  }

  // à optimiser : render *3 ?
  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.data.myPost !== this.props.data.myPost || prevProps.data.isLoading !== this.props.data.isLoading || prevProps.data.idDocument !== this.props.data.idDocument) {
    //   this.setState({ myPost: this.props.data.myPost, isLoading: this.props.data.isLoading, idDocument: this.props.data.idDocument });
    // }
    if (prevProps.data !== this.props.data) {
      this.setState({ myPost: this.props.data.myPost, isLoading: this.props.data.isLoading, idDocument: this.props.data.idDocument });
    }
  }

  render() {
    return <Fragment>{this.state.isLoading ? <LoaderWriteBot /> : <Editor data={{ html: this.state.myPost, idDocument: this.state.idDocument }} />}</Fragment>;
  }
}

export default Viewer2;
