import React, { useState, useEffect } from "react";
import man from "../assets/images/dashboard/user.png";
import { ToastContainer, toast } from "react-toastify";

import { withRouter } from "react-router";
import { firebase_app, googleProvider, Jwt_token } from "../data/config";
import GoogleLogo from "./googleIcon.svg";
import logoWriteBot from "../assets/writebot/WriteBotLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const Signin = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  useEffect(() => {
    if (value !== null) localStorage.setItem("profileURL", value);
    else localStorage.setItem("profileURL", man);
  }, [value]);

  const loginAuth = async () => {
    try {
      await firebase_app.auth().signInWithEmailAndPassword(email, password);
      setValue(man);
      localStorage.setItem("token", Jwt_token);
      history.push(`${process.env.PUBLIC_URL}/`);
    } catch (error) {
      setTimeout(() => {
        toast.error("E-mail ou mot de passe invalide");
      }, 200);
    }
  };

  const googleAuth = async () => {
    try {
      firebase_app
        .auth()
        .signInWithPopup(googleProvider)
        .then(function (result) {
          localStorage.setItem("token", Jwt_token);
          setTimeout(() => {
            history.push(`${process.env.PUBLIC_URL}/`);
          }, 200);
        });
    } catch (error) {
      setTimeout(() => {
        toast.error("Email ou mot de passe invalide");
      }, 200);
    }
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={logoWriteBot} alt="Main Logo" style={{ height: "80px" }} />
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <h3>{"Log In"}</h3>
                        </div>
                        <form className="theme-form">
                          <div className="form-group">
                            <label className="col-form-label pt-0">{"E-mail"}</label>
                            <input className="form-control" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email address" />
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">{"Password"}</label>
                            <div style={{ display: "flex", position: "relative" }}>
                              <input className="form-control" type={passwordShown ? "text" : "password"} name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                              <FontAwesomeIcon onClick={togglePasswordVisiblity} icon={faEye} style={{ position: "absolute", top: "30%", right: "4%" }} />
                            </div>
                            <a href={`${process.env.PUBLIC_URL}/resetPwd`}>Forgot your password ?</a>
                          </div>
                          {/* <div className="checkbox p-0">
                            <input id="checkbox1" type="checkbox" />
                            <label htmlFor="checkbox1">
                              {"Se souvenir de moi"}
                            </label>
                          </div> */}
                          <div className="form-group form-row mt-3 mb-0">
                            <button className="btn-action-light btn-block" type="button" style={{ fontSize: "14px" }} onClick={() => loginAuth()}>
                              {"Sign In"}
                            </button>
                          </div>

                          <div className="login-divider"></div>
                          <div className="social mt-3">
                            <div className="form-group btn-showcase d-flex">
                              <button className="btn btn-action-light" type="button" onClick={googleAuth}>
                                <img src={GoogleLogo} alt="logo Google" style={{ height: "20px" }} />
                                {/* <i className="fa fa-google"> </i> */}
                                {"  Continue with Google"}
                              </button>
                            </div>
                          </div>
                        </form>

                        <span className="text-center mt-2" style={{ fontSize: "14px" }}>
                          <a className="btn-action-light btn-block" href={`${process.env.PUBLIC_URL}/signup`}>
                            {"No account ? Join us !"}
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Signin);
