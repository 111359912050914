import { $createParagraphNode, $createTextNode, $getRoot } from "lexical";
import { $createListItemNode, $createListNode } from "@lexical/list";
import { $createHeadingNode, $createQuoteNode } from "@lexical/rich-text";
import { $createLinkNode } from "@lexical/link";

export default function prepopulatedText() {
  const root = $getRoot();

  const heading = $createHeadingNode("h1");
  heading.append($createTextNode("Welcome to the Writebots Editor!"));
  root.append(heading);

  const paragraph = $createParagraphNode();
  paragraph.append(
    $createTextNode(`Here, you have a variety of tools at your disposal to help you create and edit your content `),
    $createTextNode(`bold`).toggleFormat(`bold`),
    $createTextNode(`, `),
    $createTextNode(`italic`).toggleFormat(`italic`),
    $createTextNode(` and so on.`)
  );
  root.append(paragraph);

  const paragraph2 = $createParagraphNode();
  paragraph2.append($createTextNode(`You can also:`));
  root.append(paragraph2);

  const list = $createListNode(`bullet`);
  list.append(
    $createListItemNode().append($createTextNode(`Rework or Start a document`)),
    $createListItemNode().append($createTextNode(`And use the onboard product to modify your content.`)),
    $createListItemNode().append($createTextNode(`Expand your paragraphs, create outlines or reformulate your sentences !`))
  );
  root.append(list);

  const paragraph3 = $createParagraphNode();
  paragraph3.append(
    $createTextNode(``),
    $createTextNode(`When you are finished, you can `),
    $createTextNode(`Save your work`).toggleFormat(`bold`),
    $createTextNode(`,and access all of your projects `),
    $createLinkNode(`https://app.writebot.io/profile`).append($createTextNode(`from the Profile page`))
  );
  root.append(paragraph3);

  const paragraph4 = $createParagraphNode();
  paragraph4.append(
    $createTextNode(``),
    $createTextNode(`Maintain consistent formatting on your blog`),
    $createTextNode(` with the copy option`).toggleFormat(`bold`),
    $createTextNode(` which allows you to easily duplicate `),
    $createTextNode(`HTML formatting`).toggleFormat(`bold`),
    $createTextNode(`. Simply click on Copy and paste it to apply it to new content.`)
  );
  root.append(paragraph4);
  const paragraph5 = $createParagraphNode();
  paragraph5.append($createTextNode(`Let your imagination be your only limit with the Writebots !`).toggleFormat(`bold`));
  root.append(paragraph5);
}
