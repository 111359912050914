import React, { Component, useState, useEffect } from "react";

const LoaderWriteBot = () => {
  // const sentences = [
  //   { id: 1, name: "Working on the subject..." },
  //   { id: 2, name: "Consulting the National Archive..." },
  //   { id: 3, name: "Looking for inspiration..." },
  //   { id: 4, name: "Need a coffee break..." },
  //   { id: 5, name: "Plugging my keyboard..." },
  //   { id: 6, name: "Yes, I'm on it !" },
  //   { id: 7, name: "Oops, forgot to save my document..." },
  //   { id: 8, name: "Need more coffee !" },
  //   { id: 9, name: "Just came back from my break" },
  //   { id: 10, name: "In a minute!" },
  //   { id: 11, name: "Interesting theme!" },
  //   { id: 12, name: "Still need time" },
  //   { id: 13, name: "Let me double check!" },
  // ];

  // const [index, setIndex] = useState(0);
  // const [sentence, setSentence] = useState(sentences[index]);

  // useEffect(() => {
  //   setSentence(sentences[index]);
  // }, [index]);

  // useEffect(() => {
  //   const interval = setTimeout(() => {
  //     setIndex(Math.floor(Math.random() * sentences.length));
  //   }, 4000);
  // }, [sentence]);

  return (
    <div className="row align-items-center justify-content-md-center p-t-50 p-b-50">
      <div className="col-12 text-center ">
        <div className="lds-dual-ring"></div>

        {/* <p style={{ fontFamily: "monospace" }}>{`> ${sentence.name}`}</p> */}
      </div>
    </div>
  );
};

export default LoaderWriteBot;
